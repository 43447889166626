import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {track} from '@amplitude/analytics-browser'
import {matchPath} from 'react-router-dom'
import {fromQueryString, toQueryString} from '../utils'

const PAGE_TITLE = {
  '/': 'Dashboard',
  '/explore-summary-data': 'Explore Summary Data',
  '/explore-procedure-data': 'Explore Procedure Data',
  '/top-insights-v2': 'Top Insights',
  '/top-insights-v2/:id': 'Insight Details',
  '/top-insights-v2/:insightId/actionable-insights/research-summary/:researchSummaryId':
    'Research Summary',
  '/pac-tools-v2': 'PAC Tools',
  '/throughput-v2': 'Patient Volumes',
  '/data-import-report/procedures': 'Data Import Report - Procedures',
  '/data-import-report/physicians': 'Data Import Report - Physicians',
  '/data-import-report/cases': 'Data Import Report - Cases',
  '/billing': 'Billing Tool',
  '/knowledge-center': 'Knowledge Center',
  '/knowledge-center/:videoId': 'Knowledge Center',
  '/contact-us': 'Contact Us',
  '/profile': 'Profile',
  '/hospitals': 'Hospitals',
  '/alerts-v2': 'Alerts',
  '/change-password': 'Change Password',
  '/logout': 'Logout',
  '/scorecard-management': 'Scorecard Management',
  '/scorecard': 'Scorecard',
  '/goals-v2': 'Goals',
  '/goals-v2/:id': 'Goal Details',
  '/flow': 'Process Maps',
  '/personnel': 'Personnel',
  '/scenario': 'Scenario Planning',
  '/manage': 'Management Dashboard',
  '/reset': 'Reset Password',
  '/admin': 'Admin',
}

const getPageTitle = pathname => {
  for (const route in PAGE_TITLE) {
    const match = matchPath(pathname, {
      path: route,
      exact: true,
      strict: false,
    })
    if (match) {
      return PAGE_TITLE[route]
    }
  }
  // Default title if no match is found
  return pathname
}

const MASKED = 'xxxxxxxxxx'

const getChipLabel = chip => {
  if (chip?.type === 'hospital_case_numbers') return `Case Number: ${MASKED}`
  if (chip?.label?.includes('Case Number')) return `Case Number: ${MASKED}`
  return chip?.label
}

const getSearchString = search => {
  let _search = search.startsWith('?') ? search.slice(1) : search
  const {hospital_case_numbers, ...params} = fromQueryString(_search)
  if (hospital_case_numbers) {
    params.hospital_case_numbers = MASKED
  }
  const query = toQueryString(params)
  return query?.startsWith('?') ? query.slice(1) : query
}

const useTrackPage = details => {
  const {pathname, search} = useLocation()
  useEffect(() => {
    let Title = getPageTitle(pathname)

    if (details?.title) {
      Title = `${Title} - ${details?.title}`
    }

    const Chips = details?.chips?.map(c => getChipLabel(c))

    const Search = getSearchString(search)

    const payload = details?.chips
      ? {
          Title,
          Chips,
          Path: pathname,
          Search: Search,
        }
      : {
          Title,
          Path: pathname,
          Search: Search,
        }
    document.title = Title ? `CareMeasurement - ${Title}` : 'CareMeasurement'
    track('Page Viewed', payload)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search])
}

export default useTrackPage
